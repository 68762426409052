
// import React from "react";
// import ReactDOM from "react-dom";
import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";
// import moment, { relativeTimeThreshold, duration } from 'moment'
// import { Popover, OverlayTrigger } from "react-bootstrap"
import React from 'react'
  import ReactDOM from 'react-dom';
  import axios from 'axios';
  import '../../styles/main.css';
  import ReactTable from 'react-table-6'
  import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
  import 'react-table-6/react-table.css'
  import { array, element } from 'prop-types';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/js/bootstrap.bundle';
  import '../../styles/navbar.css';
  import Modal from "react-bootstrap/Modal";
  import moment, { relativeTimeThreshold, duration } from 'moment'
  import swal from '@sweetalert/with-react';
  import date from "date-and-time";
  import Spinner from 'react-bootstrap/Spinner'
  import { white } from 'color-name';
  import { formatDate } from 'tough-cookie';
  import { Popover, OverlayTrigger } from "react-bootstrap"
  import { Chart } from 'react-charts'
  import GlobalFunctions from '../../controllers/globalFunctions';
  import Select from 'react-select';
  import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
  import { Progress } from 'react-sweet-progress';
  import "react-sweet-progress/lib/style.css";
  import DatePicker from "react-datepicker";
  import { BsEyeFill } from "react-icons/bs";
  // import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
  import 'rsuite-table/dist/css/rsuite-table.css';
  
  import "react-datepicker/dist/react-datepicker.css";
///IMPORTING COMPONENTS;
import SweetAlertProgressBar from './SweetAlerts';

// Table data as a array of objects
// let list = [];
// for (let i = 0; i < 1000; i++) {
//   list.push({ name: `${i} Brian Vaughn`, description: "Software engineer" });
// }

export default class BigdataTable extends React.Component {
  constructor(props) {
    super(props);
    const sortBy = "staffName";
    const sortDirection = SortDirection.ASC;
    this.state = {
      sortBy,
      sortDirection,
      sortedList : [],
      data : [],
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      supportServicesPerClient : [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      addExtraHourForInvoicing : false,
      selectedShiftDetails: {},
      financialPackageId: 0,
      allowance: 0,
      userDetails: {},
      totalShifts: {
        totalAdminConfirmed:0,
        totalCompleted : 0,
        totalApproved : 0,
        totalShifts : 0
      },
      copiedWeekShifts: [],
      getNextWeeks: [],
      clientLocation: "",
      staffAppointments: [],
      costPerHour: 0,
      calculatedDuration: 0,
      editedAppointments: {
        clientId:0,
        staffId: 0,
        appointmentId: 0,
        startTime: "",
        endTime: "",
        serviceId: 0,
        location: "",
        locationId:0,
        notes: "",
        date : ""
      },
      isCordinator: false,
      availability: false,
      loadingData: true,
      savingData: false,
      isOpenListOfServices: false,
      selectedServiceName: '',
      isSubmitingInProgress: false,
      clientList: [],
      staffList : [],
      shiftsList: [],
      filteredData: [],
      searchInput: '',
      selectedListClientAppointmentIds : [],
      selectedRowId : 0,
      selectedLocationShiftsIds : [],
      selectedStaffIds : [],
      clientShiftOptionsShow : true,
      locationShifts : [],
      companyLocations : [],
      hideLocationShiftInputFields : false,
      lastFilterApplied : 'Location',
      coppiedShistDetails : {locationShifts :[], appointments : []},
      filterSchedulesByLocation : 0,
      filterStaffByLocationValue : {label : 'Filter By Location', value : 0},
      locationSelectList : [
        {label : 'All Locations', value : 0}
      ],
      userLocationData : [],
      staffUnderSelectedFilter : [],
      userManagerData : [],
      filterSchedulesByManagerId : 0,
      filterSchedulesByManagerValue : {label : 'Filter By Manager', value : 0},
      managersSeleectList : [
        {label : 'All Managers', value : 0}
      ],
      clientSelectedValue : {label : 'Select Participant', value : 0},
      clientSelectedValueEdit :null,
      clientSelectList : [],
      serviceSelectedValue : {label : '', value : 0},
      selectedFilterItermId : 0, 
      serviceSelectList : [],
      serviceSelectListEdit : [],
      staffUnderManager : [],
      staffUnderLocation : [],
      isStaffAvailable : true,
      isActiveSleepOver : null,
      startTime: '',
      endTime : '',
      notes : '',
      staffId :0,
      progress : 0,
      breakMin : 0,
      clientLocaton:'',
      shiftId : 0,
      locationShiftEditModal : false,
      remainingWeekDays : [], 
      selectedRecuringDays : [],
      upcomingWeekDays : [],
      weekOrFortnightSelected: false,
      selectedReatPeriod : 'ThisWeek',
      trackingItemID : ''
    };

  }
  getLocationUserData = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userProperty?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({userLocationData : res.data});
    })
  }

  getManagerUserData = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/userManager?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({userManagerData : res.data});
    })
  }

  handleChangeStaffNameFilter= event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.filterStaffByName();
    });
  };

  handleChangeLocationIdFilter = event => {
    this.setState({ filterSchedulesByLocation : event.value , filterStaffByLocationValue : event}, () => {
      this.filterStaffByLocation();
    });
  };

  filterStaffByName = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        value.staffName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ DatabaseData: filteredData });
  };

  filterStaffByLocation = () => {
    const arr = this.state.staffUnderSelectedFilter;
    let { filterSchedulesByLocation } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        arr.includes(value.staffId)
      );
    });
    if (Number(filterSchedulesByLocation)==0) {
      this.setState({ DatabaseData:  this.state.shiftsList, staffUnderSelectedFilter : []});
    }else{
      this.setState({ DatabaseData: filteredData, staffUnderSelectedFilter : [] });
    }
  };
  
  handleChangeManagerIdFilter = event => {
    this.setState({ filterSchedulesByManagerId : event.value, filterSchedulesByManagerValue: event }, () => {
      this.filterStaffByManager();
    });
  };

  filterStaffByManager = () => {
    const arr = this.state.staffUnderSelectedFilter;
    let { filterSchedulesByManagerId } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
       arr.includes(value.staffId)
      );
    });
    if (Number(filterSchedulesByManagerId)==0) {
      this.setState({ DatabaseData:  this.state.shiftsList, staffUnderSelectedFilter : []});
    }else{
      this.setState({ DatabaseData: filteredData , staffUnderSelectedFilter : []});
    }
  };

  manageFiltersAfterUpdate =()=>{
    if (this.state.lastFilterApplied=='Location') {
      var pos = 0 ;
      this.state.userLocationData.forEach(element=>{
        pos++;
        if(this.state.selectedFilterItermId==element.propertId){
          this.state.staffUnderSelectedFilter.push(element.staffId);
        }
        if(pos==this.state.userLocationData.length){
          this.filterStaffByLocation();
        }
      })
    }else if(this.state.lastFilterApplied=='Staff'){
      this.filterStaffByName();
    }else if(this.state.lastFilterApplied=='Manager'){
      var pos = 0;
      this.state.userManagerData.forEach(element=>{
        pos++;
        if(this.state.selectedFilterItermId==element.managerId){
          this.state.staffUnderSelectedFilter.push(element.staffManaged);
        }
        if(pos==this.state.userManagerData.length){
          this.filterStaffByManager();
        }
      })
    }
  }

  handleApproveTimesheets123 = () => {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/shifts/bulkApprove?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(result => {
      this.state.DatabaseData.forEach(element=>{
        element.shifts.forEach(shift=>{
          shift.isApproved=true
        })
      })
      swal("Successfully approved!", " ", "success");
    }).catch(error => {
      console.log(error);
      swal("Not Approved!", " ", "warning");
    })
  }

  calculateDuration123 = (event) =>{
    var startTime = event.startTime&&event.endTime?date.parse(event.startTime, 'HH:mm:ss A', true):null;
    var endTime =event.startTime&&event.endTime? date.parse(event.endTime, 'HH:mm:ss A', true):null;
    var duration = event.startTime&&event.endTime?date.subtract(endTime, startTime).toHours():0;
    duration = duration<0?24+duration:duration;
    this.setState({ calculatedDuration: duration });
    return duration;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event, recuring) =>{
    event.preventDefault();
    var pos=0;
    recuring.push(this.state.selectedShiftDate);
    this.setState({ savingData: true, selectedRecuringDays: []});
    const data = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,
      "isActiveSleepOver" : this.state.isActiveSleepOver,
      "trackingItemID": this.state.trackingItemID
    }
    this.setState({
      startTime : '', 
      endTime : '', 
      notes : '', 
      breakMin : 0,
      selectedRecuringDays:[]
    })
    recuring.forEach(element=>{
      const infor = {
        "staffId": Number(data.staffId),
        "location": data.location,
        "Date": element,
        "startTime": data.startTime,
        "endTime": data.endTime,
        "breaking": data.breaking,
        "notes": data.notes,
        "allowance": 0,
        "isActiveSleepOver" : data.isActiveSleepOver,
        "trackingItemID": data.trackingItemID
      };
  
      if (Number(this.state.selectedStaffId) == -1) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.setState({ savingData: false })
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
                arr= this.state.DatabaseData;
              var arr2=this.state.locationShifts;
                  const index= arr.findIndex(x => x.staffId == Number(-1));
                  const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                  arr[index].shifts.push({
                    "shiftId" : res.data.data.shiftId,
                    "staffId": -1,
                    "locationId" : this.state.companyLocations[locationPos].id,
                    "location" : this.state.companyLocations[locationPos].id,
                    "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                    "date": res.data.data.Date,
                    "day":moment(res.data.data.Date).format('dddd'),
                    "startTime": res.data.data.startTime,
                    "endTime": res.data.data.endTime,
                    "notes": res.data.data.notes,
                    "breaking": res.data.data.breaking,
                    "isClientShift" : false,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                    "trackingItemID": data.trackingItemID
                  });
                  if(element==this.state.selectedShiftDate){
                    arr2.push({
                      "shiftId" : res.data.data.shiftId,
                      "staffId": -1,
                      "locationId" : this.state.companyLocations[locationPos].id,
                      "location" : this.state.companyLocations[locationPos].id,
                      "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                      "date": res.data.data.Date,
                      "day":moment(res.data.data.Date).format('dddd'),
                      "startTime": res.data.data.startTime,
                      "endTime": res.data.data.endTime,
                      "notes": res.data.data.notes,
                      "breaking": res.data.data.breaking,
                      "isClientShift" : false,
                      "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                      "trackingItemID": data.trackingItemID
                    })
                  }
                  
                  this.state.DatabaseData = arr;
                  this.state.locationShifts = arr2;
                  
                 
                  pos++;
              
                  this.manageFiltersAfterUpdate();
              this.saveAlert();
            }
  
          }).catch(error => {
            pos++;
            this.setState({ savingData: false })
  
          });
      } else {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.setState({ savingData: false })
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
                arr= this.state.DatabaseData;
              var arr2=this.state.locationShifts;
                  const index= arr.findIndex(x => x.staffId == Number(res.data.data.staffId));
                  const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                  arr[index].shifts.push({
                    "shiftId" : res.data.data.shiftId,
                    "staffId": res.data.data.stafffId,
                    "locationId" : this.state.companyLocations[locationPos].id,
                    "location" : this.state.companyLocations[locationPos].id,
                    "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                    "date": res.data.data.Date,
                    "day":moment(res.data.data.Date).format('dddd'),
                    "startTime": res.data.data.startTime,
                    "endTime": res.data.data.endTime,
                    "notes": res.data.data.notes,
                    "breaking": res.data.data.breaking,
                    "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                    "isClientShift" : false,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "trackingItemID": data.trackingItemID
                  });
                  if(element==this.state.selectedShiftDate){
                    arr2.push({
                      "shiftId" : res.data.data.shiftId,
                      "staffId": res.data.data.stafffId,
                      "locationId" : this.state.companyLocations[locationPos].id,
                      "location" : this.state.companyLocations[locationPos].id,
                      "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                      "date": res.data.data.Date,
                      "day":moment(res.data.data.Date).format('dddd'),
                      "startTime": res.data.data.startTime,
                      "endTime": res.data.data.endTime,
                      "notes": res.data.data.notes,
                      "breaking": res.data.data.breaking,
                      "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                      "isClientShift" : false,
                      "trackingItemID": data.trackingItemID
                    })
                  }
                  
                this.state.DatabaseData = arr;
                this.state.locationShifts = arr2;
                pos++;
                if(pos==recuring.length){
                  this.setState({
                    startTime : '', 
                    endTime : '', 
                    notes : '', 
                    breakMin : 0,
                    selectedRecuringDays:[]
                  })
                }
                this.manageFiltersAfterUpdate();
              this.saveAlert();
              this.saveAlert();
            }
  
          }).catch(error => {
            pos++;
            console.log(error)
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
            this.setState({ savingData: false })
          })
      }
    })
    


  }
  handleAddAppointment = (event, recuring) =>{
    this.setState({ savingData: true, selectedRecuringDays: [] })
    event.preventDefault();
    var pos=0;
    recuring.push(this.state.selectedShiftDate);
    var valid = true;
    const data={
      "isCordinator": this.state.isCordinator,
      "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
      "location": this.state.clientLocation,
      "date": element,
      "shiftId": Number(this.state.selectedShiftId),
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "clientId": Number(this.state.clientId),
      "notes": this.state.notes,
      "serviceId": Number(this.state.clientServiceId),
      "costPerHour": this.state.costPerHour,
      "isActiveSleepOver" : this.state.isActiveSleepOver,
      "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
      "trackingItemID": this.state.trackingItemID
    }
    if (this.state.clientId == 0 || isNaN(this.state.clientServiceId)) {
      valid = false;
      this.setState({ savingData: false });
      if (this.state.clientId == 0) {
        swal("Select Client Please!", " ", "warning");
      }
      else if (isNaN(this.state.clientServiceId)) {
        swal("Select Service Please!", " ", "warning");
      }
    }
    this.setState({
      startTime : '', 
      endTime : '', 
      notes : '', 
      clientLocation :'',
      breakMin : 0,
      serviceSelectList : [],
      serviceSelectedValue : '',
      costPerHour : 0,
      clientSelectedValue : '',
      clientId : 0,
      clientServiceId : 'No value',
      addExtraHourForInvoicing : false,
      selectedRecuringDays:[]
    })
    recuring.forEach(element=>{
      const infor = {
        "isCordinator": data.isCordinator,
        "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
        "location": data.location,
        "date": element,
        "shiftId": Number(this.state.selectedShiftId),
        "startTime": data.startTime,
        "endTime": data.endTime,
        "clientId": Number(data.clientId),
        "notes": data.notes,
        "serviceId": Number(data.serviceId),
        "costPerHour": data.costPerHour,
        "isActiveSleepOver" : data.isActiveSleepOver,
        "addExtraHourForInvoicing" : data.addExtraHourForInvoicing,
        "trackingItemID": data.trackingItemID
      };
  
      if (valid) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/appointStaff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.setState({ savingData: false });
            if (res.status == 201&&moment(res.data.data.Date)<=moment(this.state.weekEnd)) {
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              arr2=this.state.staffAppointments
                const index= arr.findIndex(x => x.staffId == this.state.selectedStaffId);
                const clientPos=this.state.clients.findIndex(x => x.id == Number(data.clientId));
                const servicePos=this.state.serviceSelectList.findIndex(x => x.value == Number(data.serviceId));
              
                arr[index].shifts.push({
                  "shiftId" : 'CS_'+res.data.data.id,
                  "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
                  "location": data.location,
                  "clientLocation" : data.location,
                  "clientLocaton" : data.location,
                  "date": res.data.data.Date,
                  "startTime": data.startTime,
                  "endTime": data.endTime,
                  "clientId": data.clientId,
                  "notes": data.notes,
                  "serviceId": Number(data.serviceId),
                  "costPerHour": data.costPerHour,
                  "day" : moment(res.data.data.Date).format('dddd'),
                  "serviceName" : servicePos>=0?this.state.serviceSelectList[servicePos].label:"",
                  "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                  "isClientShift" : true,
                  "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                  "addExtraHourForInvoicing" :  data.addExtraHourForInvoicing,
                  "trackingItemID": data.trackingItemID
                })
                if(element==this.state.selectedShiftDate){
                  arr2.push({
                    "shiftId" : 'CS_'+res.data.data.id,
                    "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
                    "location": data.location,
                    "clientLocation" : data.location,
                    "clientLocaton" : data.location,
                    "date": res.data.data.Date,
                    "startTime": data.startTime,
                    "endTime": data.endTime,
                    "clientId": data.clientId,
                    "notes": data.notes,
                    "serviceId": Number(data.serviceId),
                    "costPerHour": data.costPerHour,
                    "day" : moment(res.data.data.Date).format('dddd'),
                    "serviceName" : servicePos>=0?this.state.supportServices[servicePos].supportService:"",
                    "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                    "isClientShift" : true,
                    "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                    "addExtraHourForInvoicing" :  data.addExtraHourForInvoicing,
                    "trackingItemID": data.trackingItemID
                  })
                }
  
                this.state.DatabaseData = arr;
                this.state.staffAppointments = arr2;
                this.setState({DatabaseData: arr, staffAppointments:arr2})
                pos++;
                this.manageFiltersAfterUpdate();
              
              this.saveAppointmentAlert();
            }
          }).catch(error => {
            pos++;
            this.setState({ savingData: false })
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
  
          })
      }
    })
  }
  handleSubmitEditedAppointment = (event) =>{
    this.setState({ savingData: true })
    event.preventDefault();
    const infor = {
      "staffId": this.state.staffId,
      "location": this.state.clientLocation,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "serviceId": this.state.clientServiceId,
      "notes": this.state.notes,
      "clientId": this.state.clientId,
      "costPerHour" : this.state.costPerHour,
      "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
      "trackingItemID" : this.state.trackingItemID
    };

    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${this.state.shiftId}`, infor)
      .then(res => {
        this.setState({ savingData: false, isSubmitingInProgress: false })
        if (res.status == 200) {
          var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.staffAppointments
            if (true) {
              
              const index1= arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              var staffShifts =arr[index1].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == this.state.shiftId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.shiftId);
              arr[index1].shifts[pos]={
                "staffId": this.state.staffId,
                "location": this.state.clientLocation,
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "serviceId": this.state.clientServiceId,
                "notes": this.state.notes,
                "clientId": this.state.clientId,
                "isClientShift": true,
                "day" :  this.state.editedAppointments.day,
                "clientName" : this.state.clientSelectedValue.label,
                "clientLocaton" :  this.state.clientLocation,
                "clientLocation" : this.state.clientLocation,
                "isApproved" :  this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "shiftId" : this.state.shiftId,
                "serviceName" : this.state.serviceSelectedValue.label,
                "costPerHour" : this.state.costPerHour,
                "isNotClient" : false,
                "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
                "duration" : this.calculateDuration({startTime : this.state.startTime, endTime : this.state.endTime}),
                "trackingItemID" :  this.state.trackingItemID
              };
              arr2[pos2]={
                "staffId": this.state.staffId,
                "location": this.state.clientLocation,
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "serviceId": this.state.clientServiceId,
                "notes": this.state.notes,
                "clientId": this.state.clientId,
                "isClientShift": true,
                "day" :  this.state.editedAppointments.day,
                "clientName" : this.state.clientSelectedValue.label,
                "clientLocaton" :  this.state.clientLocation,
                "clientLocation" : this.state.clientLocation,
                "isApproved" :  this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "shiftId" : this.state.shiftId,
                "costPerHour" : this.state.costPerHour,
                "serviceName" : this.state.serviceSelectedValue.label,
                "addExtraHourForInvoicing" : this.state.addExtraHourForInvoicing,
                "duration" : this.calculateDuration({startTime : this.state.startTime, endTime : this.state.endTime}),
                "trackingItemID" :  this.state.trackingItemID
              };
              
            }else{
              const index1 = arr.findIndex(x => x.staffId == this.state.selectedShiftDetails.staffId);
              const pos = arr[index1].shifts.findIndex(x => x.shiftId == this.state.editedAppointments.appointmentId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.editedAppointments.appointmentId);
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
              arr[index1].shifts=arr[index1].shifts.slice(0, pos).concat(arr.slice(pos+1, arr[index1].shifts.length));

              const indexTo=arr.findIndex(x => x.staffId == this.state.editedAppointments.staffId);
              arr[indexTo].shifts.push({
                "staffId": infor.staffId,
                "location": infor.location,
                "startTime": infor.startTime,
                "endTime": infor.endTime,
                "serviceId": infor.serviceId,
                "notes": infor.notes,
                "clientId": infor.clientId,
                "clientName" : this.state.selectedShiftDetails.clientName,
                "isClientShift": true,
                "day" :  this.state.selectedShiftDetails.day,
                "isApproved" :  this.state.selectedShiftDetails.isApproved,
                "clientLocaton" :  this.state.selectedShiftDetails.clientLocaton,
                "shiftId" : this.state.editedAppointments.appointmentId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
              });
            
            }
            this.setState({isSubmitingInProgress: false});
            
            this.setState({DatabaseData:arr, staffAppointments : arr2})
            this.manageFiltersAfterUpdate();
            this.saveAlert();

        }
      }).catch(error => {
        console.log(error)
        this.setState({ savingData: false, isSubmitingInProgress: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })

  }

  handleEditShift = (event) => {
    event.preventDefault();
    var url;
    var infor;
    this.setState({ isSubmitingInProgress: true });
      if(this.state.selectedStaffId==-1){
        infor = {
          "location": this.state.locationId,
          "Date": this.state.selectedShiftDate ,
          "startTime": this.state.startTime,
          "endTime": this.state.endTime,
          "breaking": this.state.breakMin,
          "notes": this.state.notes,
          "isActiveSleepOver" : this.state.isActiveSleepOver,
          "trackingItemID" : this.state.trackingItemID
        };
        url=`${GlobalFunctions.extractToken().API_URL}/shifts/editOpenShift?shiftId=${this.state.shiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}`
      }else{
        infor = {
          "location": this.state.locationId,
          "Date": this.state.selectedShiftDate ,
          "startTime": this.state.startTime,
          "endTime": this.state.endTime,
          "breaking": this.state.breakMin,
          "notes": this.state.notes,
          "staffId": this.state.staffId,
          "isActiveSleepOver" : this.state.isActiveSleepOver,
          "trackingItemID" : this.state.trackingItemID
        };
        url=`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.shiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`
      }
      axios.post(url, infor)
        .then(res => {
          this.setState({ isSubmitingInProgress: false })
          if (res.status == 200) {
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts
            const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(this.state.locationId));

            if (true) {
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              var staffShifts =arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == this.state.shiftId);
              const pos2 = arr2.findIndex(x => x.shiftId == this.state.shiftId);
              arr[index].shifts[pos]={
                "location": this.state.locationId,
                "Date": this.state.selectedShiftDate,
                "day" : moment(this.state.selectedShiftDate).format('dddd'),       
                "endTime": this.state.endTime,
                "startTime": this.state.startTime,
                "breaking": this.state.breakMin,
                "notes": this.state.notes,
                "staffId": this.state.staffId,
                "shiftId" : this.state.shiftId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                "clientLocation" : this.state.companyLocations[locationPos].name,
                "isActiveSleepOver" : this.state.isActiveSleepOver,
                "isApproved" : this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "trackingItemID" : this.state.trackingItemID

              };
              arr2[pos2]={
                "location": this.state.locationId,
                "Date": this.state.selectedShiftDate,
                "day" : moment(this.state.selectedShiftDate).format('dddd'),       
                "endTime": this.state.endTime,
                "startTime": this.state.startTime,
                "breaking": this.state.breakMin,
                "notes": this.state.notes,
                "staffId": this.state.staffId,
                "shiftId" : this.state.shiftId,
                "duration" : this.calculateDuration({startTime : infor.startTime, endTime : infor.endTime}),
                "clientLocation" : this.state.companyLocations[locationPos].name,
                "isActiveSleepOver" : this.state.isActiveSleepOver,
                "isApproved" : this.state.editedAppointments.isApproved,
                "isComplete" : this.state.editedAppointments.isComplete,
                "isConfirmed" : this.state.editedAppointments.isConfirmed,
                "trackingItemID" : this.state.trackingItemID

              };
              
            }
            
            this.setState({isSubmitingInProgress: false});
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
            this.saveAlert();
          }


        }).catch(error => {
          console.log(error)
          this.setState({ isSubmitingInProgress: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    

  }

  showModal = () =>{
    this.setState({ ShowModal: true });
  }

  hideModal = () => {
    this.setState({ ShowModal: false });
  }
  showAddModal = () =>{
    this.setState({ ShowAddModal: true });
  }
  showAddAppointmentModal = (event) => {
    var dates=[]
    var pos=0;
    while(moment(event).add(pos,'days').format('MM/DD/YYYY')<moment(this.state.weekEnd).format('MM/DD/YYYY')){
      pos++;
      dates.push(moment(event).add(pos,'days').format('MM/DD/YYYY'));
    }
    this.setState({ ShowAddAppointmentModal: true, remainingWeekDays: dates});
  }
  showCopyShiftsModal = () => {
    this.setState({ ShowCopyShiftsModal: true });
  }

  hideCopyShiftsModal = () => {
    this.setState({ ShowCopyShiftsModal: false });
  }
  hideAddModal = () => {
    this.setState({ ShowAddModal: false });
  }
  hideAddAppointmentModal = () =>{
    this.setState({ staffAppointments: [] })
    this.setState({ ShowAddAppointmentModal: false });
  }
  showOpenShiftsModal = () => {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal = () => {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle = (event) => {
    this.setState({ [event.name]: event.value })
  }


  //AN HTTP REQUEST TO THE getAllShifts-endpoint
   getShifts = (event) =>{
    this.setState({ loadingData: true })
    var shiftsList = [];
    var sortedShiftList = [];
    var openShiftObject;
    var staffList = [];
    var temp = [];
    var finalData = [];
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(shifts => {
      this.setState({ shifts: shifts.data });
      //FETCHING ALL STAFF FROM API
      url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?weekStart=${event.start}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(openShift => {
          shiftsList = shifts.data;
          staffList = staff.data;
          
          //SELECTING MANAGERS FOR FILTERING PURPOSE
          this.setState({managersSeleectList :  []});
          this.state.managersSeleectList.push({label : 'All Managers', value : 0})
          staffList.forEach(element=>{
            if (element.access_level==3) {
              this.state.managersSeleectList.push({
                label : element.lastname+' '+element.firstname, 
                value : element.id
              })
            }
          })
          this.setState({ staffList: staffList });
          //ADDING OPEN SHIFTS FOR DISPLAY
          
          openShiftObject = { staffId: -1, staffName: 'Open Shifts', shifts: openShift.data };
          //GROUPING SHIFTS ACCORDING TO STAFF
          // staffList.forEach(staffElement => {
          //   shiftsList.forEach(shiftElement => {
          //     if (staffElement.id == shiftElement.staffId) {
          //       temp.push(shiftElement);
          //     }
          //   });
          //   finalData.push({ staffId: staffElement.id, propertId : staffElement.propertId, managerId : staffElement.managerId, staffName: staffElement.lastname + ' ' + staffElement.firstname, availability: staffElement.availability, shifts: temp });
          //   temp = [];
          // });
          // finalData = finalData.sort(function (a, b) {
          //   if (a.staffName < b.staffName) {
          //     return -1;
          //   }
          //   if (a.staffName > b.staffName) {
          //     return 1;
          //   }
          //   return 0;
          // })
          // sortedShiftList.push(openShiftObject);
          // finalData.forEach(element => {
          //   sortedShiftList.push(element);
          // })
          sortedShiftList.push(openShiftObject);
          sortedShiftList =sortedShiftList.concat(shifts.data)
          this.setState({ DatabaseData: sortedShiftList, shiftsList: sortedShiftList, loadingData: false })
          this.countShifts(sortedShiftList);
          return sortedShiftList;
        }).catch(error => {

        })
      }).catch(error => {

      })
    }).catch(error => {

    })
  }

  handleWeekSubmit = () => {
    var staffIds=[]
    this.state.DatabaseData.forEach(element => {
      staffIds.push(Number(element.staffId));
    });
    if (this.state.selectedStaffIds.length==0) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?start=${this.state.weekStart}&end=${this.state.weekEnd}&staffIds=${staffIds}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1,{copiedWeekShifts:this.state.copiedWeekShifts, staffIds : staffIds} ).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
          this.setState({selectedStaffIds:[]})
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
    }else{
      this.state.selectedStaffIds.forEach(element=>{
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?staffId=${element}&start=${this.state.weekStart}&end=${this.state.weekEnd}&staffIds=${staffIds}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1, {copiedWeekShifts:this.state.copiedWeekShifts, staffIds : staffIds}).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
      })
    }
    

    this.setState({selectedStaffIds:[]});
    this.setState({ copiedWeekShifts: [] });
  }

  countShifts = (event) => {
    var shifts = 0;
    var approved = 0;
    var completed = 0;
    var adminApproved = 0;
    event.forEach(element => {
      if (true) {
        element.shifts.forEach(element2 => {
          shifts++
          if (element2.isApproved) {
            approved++
          }
          if (element2.isCompleted || element2.isComplete) {
            completed++
          }
          if (element2.isAdminConfirmed || element2.isConfirmed) {
            adminApproved++
          }
        })
      }

      this.setState({ totalShifts: { totalShifts: shifts, totalApproved: approved, totalAdminConfirmed: adminApproved, totalCompleted: completed } });
      this.manageFiltersAfterUpdate();

    })

  }

  getClients = () => {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
      client.data.forEach(element=>{
        this.state.clientSelectList.push({
          label : element.clientname,
          value : element.id
        })
      })
    })

  }

  getAppointments = (event) => {
    this.setState({ loadingData: true })

    if (event.isCordinator) {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/getAppointments?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&shiftId=${event.shiftId}`;
      axios.get(url1).then(client => {
        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    } else {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staffclientshift?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}&date=${this.state.selectedShiftDate}`;
      axios.get(url1).then(client => {
        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    }
  }

  getParticipantServices = (event)=>{
    this.setState({ supportServicesPerClient: [], serviceSelectedValue :{label : 'Loading Client Services from server...', value : 0} });
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/participantServices?clientId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServicesPerClient: client.data, serviceSelectedValue : null});
      client.data.forEach(element =>{
        this.state.serviceSelectList.push({
          value : element.supportServiceId,
          label : element.supportService
        })
      })
    }).catch(error =>{
      this.setState({serviceSelectedValue :{label : 'An error occured, check your connection & reselect client', value : 0}  })
    })
  }

  deleteAppointmentAlert=(event)=>{
    var BreakException = {};
    swal({
      title: "Selected Items will be Deleted",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      try {
        var pos =0;
        event.forEach(data=>{
          const completeStatus=data.isComplete;
          if(completeStatus) throw BreakException;
          pos++;
          if(pos==event.length){
            this.deleteShift(event);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Completed and Confirmed shifts",
            text: "NOTE Completed and Confirmed  will not be Deleted",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.deleteShift(event);
           }
          })
      }
     }
    })
    
}

  deleteShift = (ids) =>{
    this.setState({ savingData: true, loadingData : true })
    var arrypos=0;
    var success=0;
    this.state.selectedListClientAppointmentIds.forEach(data=>{
      var id = data.shiftId
      if(!isNaN(id)){
        if (this.state.selectedStaffId == -1) {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteOpenShift?shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            arrypos++; 
            success++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            this.setState({ savingData: false })
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts;
  
            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == id);
            const pos2 = arr2.findIndex(x => x.shiftId == id);
            // Checking for complete status in order to delete
            const completeStatus=!(staffShifts[pos].isComplete||staffShifts[pos].isCompleted);
            if(index>-1&&pos2>-1){
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
            }
            
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
            
          }).catch(error => {
            arrypos++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
          })
    
        } else {
          var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${id}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            arrypos++; 
            success++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            this.setState({ savingData: false })
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.locationShifts;
  
            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == id);
            const pos2 = arr2.findIndex(x => x.shiftId == id);

            // Checking for complete status in order to delete
            const completeStatus = !(staffShifts[pos].isComplete ||staffShifts[pos].isCompleted);
            if(pos2>-1&&index>-1){
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
            arr2=this.state.locationShifts.slice(0, pos2).concat(this.state.locationShifts.slice(pos2+1, this.state.locationShifts.length));
            }
            
            this.setState({DatabaseData : arr, locationShifts : arr2})
            this.manageFiltersAfterUpdate();
    
          }).catch(error => {
            arrypos++;
            this.deletedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
          })
        }
      }else{
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteAppointment?appointmentId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          arrypos++; 
          success++;
          this.deletedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
          this.setState({ savingData: false });
          var arr =[];
          var arr2 = [];
          arr= this.state.DatabaseData;
          arr2=this.state.staffAppointments;
          const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
          const staffShifts =arr[index].shifts;
          const pos = staffShifts.findIndex(x => x.shiftId == id);
          const pos2 = arr2.findIndex(x => x.shiftId == id);

          // Checking for complete status in order to delete
          const completeStatus = !(staffShifts[pos].isComplete||staffShifts[pos].isCompleted);
          if (index>-1&&pos2>-1) {
            arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
            arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
          }
          this.state.DatabaseData = arr;
            this.state.staffAppointments = arr2;
            this.manageFiltersAfterUpdate();
        }).catch(error => {
          arrypos++;
          this.deletedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
        })
      }
    })

  }

  deletedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is deleted successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }
  

  handleApproveTimeSheetsAlert =()=>{
    swal({
      title: "The selected shifts will be approved",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
     if(willDelete){
      this.handleApproveAppointments(this.state.selectedListClientAppointmentIds);
     }
    })
  }

  handleApproveAppointments = (ids) => {
    var arrypos=0;
    var success=0;
    this.setState({loadingData : true})
    ids.forEach(data=>{
      var element = data.shiftId
      if(isNaN(element)){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approveAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
            this.setState({selectedListClientAppointmentIds : []})
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.staffAppointments

            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == element);
            const pos2 = arr2.findIndex(x => x.shiftId == element);
            staffShifts[pos].isApproved=true;
            arr2[pos2].isApproved=true;
            this.setState({DatabaseData : arr, staffAppointments : arr2});
            arrypos++; 
            success++;
            this.approvedShiftAlert(ids.length, success);
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            this.manageFiltersAfterUpdate();
  
        }).catch(error => {
          arrypos++;
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
          this.approvedShiftAlert(ids.length, success);
        })
      }else{
          var element = data.shiftId;
          if(!isNaN(element)){
            var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approve?shiftId=${element}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
            axios.get(url1).then(client => {
                  var arr =[];
                  var arr2 = [];
                  arr= this.state.DatabaseData;
                  arr2=this.state.locationShifts
    
                  const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
                  const staffShifts =arr[index].shifts;
                  const pos = staffShifts.findIndex(x => x.shiftId == element);
                  const pos2 = arr2.findIndex(x => x.shiftId == element);
                  staffShifts[pos].isApproved=true;
                  arr2[pos2].isApproved=true;
                  this.setState({DatabaseData : arr, locationShifts : arr2})
                  arrypos++; 
                  success++;
                  this.approvedShiftAlert(ids.length, success);
                  if(ids.length==arrypos){
                    this.setState({loadingData : false})
                  }
                this.manageFiltersAfterUpdate();
              
            }).catch(error => {
              arrypos++;
              this.approvedShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
            })
          }
      }
      
    })

  }

  approvedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is Successfully approved`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }

  confirmAppointmentAlert_01=(event)=>{
    swal({
      title: "Selected shifts will be confirmed",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      var BreakException = {};
      try {
        var pos =0;
        this.state.selectedListClientAppointmentIds.forEach(data=>{
          if(!(data.isComplete||data.isConcelled==true)) throw BreakException;
          pos++;
          if(pos==this.state.selectedListClientAppointmentIds.length){
            this.handleConfirmAppointments(this.state.selectedListClientAppointmentIds);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected Incomplete Shift",
            text: "Those shifts will won't be confirmed. Cancelled Shifts will become Open Shifts",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleConfirmAppointments(this.state.selectedListClientAppointmentIds);
           }
          })
      }
     }
    })
      
  }


  handleConfirmAppointments = (ids) => {
    var arrypos=0;
    var success=0;
    this.setState({ savingData: true, loadingData : true })

    ids.forEach(data=>{
      var element = data.shiftId;
      if(isNaN(element)){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirmAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
            arrypos++; 
            success++;
            if(ids.length==arrypos){
              this.setState({loadingData : false})
            }
            var arr =[];
            var arr2 = [];
            arr= this.state.DatabaseData;
            arr2=this.state.staffAppointments

            const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
            const staffShifts =arr[index].shifts;
            const pos = staffShifts.findIndex(x => x.shiftId == element);
            const pos2 = arr2.findIndex(x => x.shiftId == element);
            if(staffShifts[pos].isComplete||staffShifts[pos].isCompleted||staffShifts[pos].isConcelled){
              staffShifts[pos].isConfirmed=true;
              arr2[pos2].isConfirmed=true;
            }
            console.log(pos2)
            console.log(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled)
            if(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled){
              staffShifts[pos].isApproved=false;
              arr2[pos2].isConfirmed=false;
              arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
              const openShiftPosition=arr.findIndex(x => x.staffId == -1);
              arr[openShiftPosition].shifts.push(staffShifts[pos]);
              arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
            }
            
            this.setState({ savingData: false, DatabaseData : arr, staffAppointments : arr2});
            this.state.staffAppointments=arr2;
            this.confirmedShiftAlert(ids.length, success);
            this.manageFiltersAfterUpdate();
          
        }).catch(error => {
          this.setState({ savingData: false })
          arrypos++;
          this.confirmedShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
        })
      }else{
        
            var id = data.shiftId;
            var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/adminConfirm?shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
            axios.get(url1).then(client => {
              arrypos++; 
              success++;
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
              this.setState({ savingData: false })
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              const staffShifts =  arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == id);
              arr2=this.state.locationShifts
              const pos2 = arr2.findIndex(x => x.shiftId == id);
              if(staffShifts[pos].isConcelled||staffShifts[pos].isComplete||staffShifts[pos].isCompleted){
                staffShifts[pos].isConfirmed=true;
                arr2[pos2].isConfirmed=true;
              }
              console.log(pos2)
              console.log(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled)
              if(staffShifts[pos].isConfirmed&&staffShifts[pos].isConcelled){
                staffShifts[pos].isApproved=false;
                arr2[pos2].isConfirmed=false; 
                arr[index].shifts=staffShifts.slice(0, pos).concat(staffShifts.slice(pos+1, staffShifts.length));
                const openShiftPosition=arr.findIndex(x => x.staffId == -1);
                arr[openShiftPosition].shifts.push(staffShifts[pos]);
                arr2=arr2.slice(0, pos2).concat(arr2.slice(pos2+1, arr2.length));
              }
              
              this.setState({DatabaseData : arr, locationShifts : arr2})
              this.confirmedShiftAlert(ids.length, success);
              this.manageFiltersAfterUpdate();
            }).catch(error =>{
              arrypos++;
              this.confirmedShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
              this.setState({ savingData: false })
            })
          
      }
      
    })

  }

  confirmedShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is successfully confirmed`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }
 
  cancelAppointmentAlert=(event)=>{
    var BreakException = {};
    swal({
      title: "The selected shifts will be cancelled",
      buttons: true,
      dangerMode: true,
    }).then((willDelete1) => {
     if(willDelete1){
      try {
        var pos =0;
        this.state.selectedListClientAppointmentIds.forEach(data=>{
          if(data.isComplete==true||data.isCompleted==true) throw BreakException;
          pos++;
          if(pos==this.state.selectedListClientAppointmentIds.length){
            this.handleCancelClientAppointmentShift(this.state.selectedListClientAppointmentIds);
          }
        })
      } catch (e) {
          swal({
            title: "You have selected completed or confirmed  shifts",
            text: "The will NOT be Cancelled",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
           if(willDelete){
            this.handleCancelClientAppointmentShift(this.state.selectedListClientAppointmentIds);
           }
          })
      }
     }
    })
    
}
  handleCancelClientAppointmentShift = (ids) =>{
    var pos = 0;
    var arrypos=0;
    var success=0;
    this.setState({loadingData : true})
    ids.forEach(data=>{
      var element= data.shiftId
      if(isNaN(element)){
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          arrypos++; 
          success++;
          this.cancelledShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
          var arr =[];
          var arr2 = [];
          arr= this.state.DatabaseData;
          arr2=this.state.staffAppointments

          const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
          const staffShifts =arr[index].shifts;
          const pos = staffShifts.findIndex(x => x.shiftId == element);
          const pos2 = arr2.findIndex(x => x.shiftId == element);
          
          if(!(staffShifts[pos].isComplete==true || staffShifts[pos].isCompleted==true)){
            staffShifts[pos].isConcelled=true;
            arr2[pos2].isConcelled=true
          }
          
          this.state.DatabaseData = arr;
          this.state.staffAppointments = arr2;
          this.manageFiltersAfterUpdate();
        }).catch(error => {
          this.setState({ savingData: false })
          arrypos++;
          this.cancelledShiftAlert(ids.length, success);
          if(ids.length==arrypos){
            this.setState({loadingData : false})
          }
        })
      }else{
          var id = data.shiftId;
          if(!isNaN(id)){
            var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancel?shiftId=${id}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
            axios.get(url1).then(client => {
              arrypos++; 
              success++;
              this.cancelledShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
              var arr =[];
              var arr2 = [];
              arr= this.state.DatabaseData;
              arr2=this.state.locationShifts;
    
              const index = arr.findIndex(x => x.staffId == this.state.selectedStaffId);
              const staffShifts =arr[index].shifts;
              const pos = staffShifts.findIndex(x => x.shiftId == id);
              const pos2 = arr2.findIndex(x => x.shiftId == id);
              if(!(staffShifts[pos].isComplete == true||staffShifts[pos].isCompleted == true)){
                arr[index].shifts[pos].isConcelled=true;
                arr2[pos2].isConcelled=true
              }
             
                this.setState({DatabaseData : arr, locationShifts : arr2})
                this.manageFiltersAfterUpdate();
            }).catch(error => {
              this.setState({ savingData: false })
              arrypos++;
              this.cancelledShiftAlert(ids.length, success);
              if(ids.length==arrypos){
                this.setState({loadingData : false})
              }
            })
          }
      }
      
    })
    
  }
  cancelledShiftAlert = (total, success)  => {
    var percentageSuccess=(success/total)*100;
    percentageSuccess = parseFloat(percentageSuccess).toFixed(0);
    swal(<SweetAlertProgressBar hearder={`${percentageSuccess}% of the shifts is cancelled successfully`} percent={percentageSuccess}></SweetAlertProgressBar>)
      .then(() => {
        this.setState({selectedListClientAppointmentIds:[]})
      })
  }

  getDayLocationShifts = () =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/dayShifts?staffId=${this.state.selectedStaffId}&date=${this.state.selectedShiftDate}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(res=>{
      this.setState({locationShifts: res.data});
    }).catch(error => {
      this.setState({ savingData: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }
  calculateWeekRange = () => {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday()) - 1
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
  
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end });
    this.getShifts({ start: start, end: end });
  }

  getNextWeek = () => {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  getPreviousWeek = () => {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }
  calculateDuration = (params) =>{
    var startTime = date.parse(params.startTime, 'HH:mm:ss A', true);
    var endTime = date.parse(params.endTime, 'HH:mm:ss A', true);
    var duration = date.subtract(endTime, startTime).toHours();
    duration = parseFloat(duration<0?24+duration:duration).toFixed(2)
    return Number(duration);
}
  pasteShift = (date, selectedStaffId) => {
   this.state.coppiedShistDetails.locationShifts.forEach(element=>{
      const infor = {
        "staffId": Number(selectedStaffId),
        "location": element.locationId,
        "Date": date,
        "startTime": element.startTime,
        "endTime": element.endTime,
        "breaking": element.breaking,
        "notes": element.notes,
        "allowance": 0,
        "isActiveSleepOver" : element.isActiveSleepOver,
        "trackingItemID" : element.trackingItemID
      };
      if (Number(selectedStaffId) == -1) {
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            var arr =[];
              arr= this.state.DatabaseData;
                const index= arr.findIndex(x => x.staffId == Number(-1));
                const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                arr[index].shifts.push({
                  "shiftId" : res.data.data.shiftId,
                  "staffId": -1,
                  "locationId" : this.state.companyLocations[locationPos].id,
                  "location" : this.state.companyLocations[locationPos].id,
                  "clientLocation" :locationPos>=0? this.state.companyLocations[locationPos].name:"",
                  "date": date,
                  "day":moment(date).format('dddd'),
                  "startTime": res.data.data.startTime,
                  "endTime": res.data.data.endTime,
                  "duration" : this.calculateDuration({startTime : res.data.data.startTime, endTime : res.data.data.endTime}),
                  "notes": res.data.data.notes,
                  "breaking": res.data.data.breaking,
                  "isClientShift" : false,
                  "isActiveSleepOver" : res.data.data.isActiveSleepOver,
                  "trackingItemID" : res.data.data.trackingItemID
                })
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                this.manageFiltersAfterUpdate();
  
          }).catch(error => {
            console.log(error)
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
          });
        } else {
          axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
            .then(res => {
              var arr =[];
              arr= this.state.DatabaseData;
                const index= arr.findIndex(x => x.staffId == Number(res.data.data.staffId));
                const locationPos=this.state.companyLocations.findIndex(x => x.id == Number(res.data.data.location));
                arr[index].shifts.push({
                  "shiftId" : res.data.data.shiftId,
                  "staffId": res.data.data.staffId,
                  "locationId" : this.state.companyLocations[locationPos].id,
                  "location" : this.state.companyLocations[locationPos].id,
                  "clientLocation" : this.state.companyLocations[locationPos].name,
                  "date": date,
                  "day":moment(date).format('dddd'),
                  "startTime": element.startTime,
                  "endTime": element.endTime,
                  "duration" : this.calculateDuration({startTime : element.startTime, endTime : element.endTime}),
                  "notes": element.notes,
                  "breaking": element.breaking,
                  "isClientShift" : false,
                  "isActiveSleepOver" : element.isActiveSleepOver,
                  "trackingItemID" : res.data.data.trackingItemID
                })
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                this.manageFiltersAfterUpdate();
                //this.filterStaffByLocation();
            }).catch(error => {
              console.log(error)
              let errorObject = JSON.parse(JSON.stringify(error));
              this.networkFailureAlert(errorObject.message)
            })
        }

   })
   this.state.coppiedShistDetails.appointments.forEach(element=>{
     if(isNaN(element.shiftId)){
      const infor = {
        "staffId": Number(selectedStaffId)>0?Number(selectedStaffId):null,
        "location": element.clientLocaton,
        "date": date,
        "startTime": element.startTime,
        "endTime": element.endTime,
        "clientId": Number(element.clientId),
        "notes": element.notes,
        "serviceId": Number(element.serviceId),
        "costPerHour": element.costPerHour,
        "addExtraHourForInvoicing" : element.addExtraHourForInvoicing,
        "trackingItemID" : element.trackingItemID
      };
        axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/appointStaff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
          .then(res => {
            this.setState({ savingData: false });
            if (res.status == 201) {
              var arr =[];
              arr= this.state.DatabaseData;
                var index=arr.findIndex(x => x.staffId == Number(selectedStaffId));
                const clientPos=this.state.clients.findIndex(x => x.id == Number(res.data.data.clientId));
                const servicePos=this.state.supportServices.findIndex(x => x.supportServiceId == Number(res.data.data.serviceId));
                arr[index].shifts.push({
                  "shiftId" : 'CS_'+res.data.data.id,
                  "staffId": res.data.data.staffId==null? -1 :res.data.data.staffId,
                  "location": res.data.data.locationId,
                  "locationId": res.data.data.locationId,
                  "clientLocation" : res.data.data.location,
                  "clientLocaton" : res.data.data.location,
                  "date": res.data.data.Date,
                  "startTime": res.data.data.startTime,
                  "endTime": res.data.data.endTime,
                  "duration" : this.calculateDuration({startTime : res.data.data.startTime, endTime : res.data.data.endTime}),
                  "clientId": Number(res.data.data.clientId),
                  "notes": res.data.data.notes,
                  "serviceId": Number(res.data.data.serviceId),
                  "costPerHour": res.data.data.costPerHour,
                  "day" : moment(res.data.data.Date).format('dddd'),
                  "serviceName" : servicePos>=0?this.state.supportServices[servicePos].supportService:"",
                  "clientName" : clientPos>=0?this.state.clients[clientPos].clientname:"",
                  "isClientShift" : true,
                  "addExtraHourForInvoicing" : res.data.data.addExtraHourForInvoicing,
                  "trackingItemID" : res.data.data.trackingItemID
                });
                this.setState({DatabaseData:arr})
                this.state.DatabaseData = arr;
                this.manageFiltersAfterUpdate();
              this.saveAppointmentAlert();
            }
          }).catch(error => {
            console.log(error)
            this.setState({ savingData: false })
            let errorObject = JSON.parse(JSON.stringify(error));
            this.networkFailureAlert(errorObject.message)
  
          })
     }
   })

  }


  calculatePasteWeek = () => {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    var arr = [];
    for (let index = 0; index < 9; index++) {
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
      arr.push({ weekStart: start, weekEnd: end });
      start = moment(end).add(1, 'days').format('MM-DD-YYYY');
    }
    this.setState({ getNextWeeks: arr });

  }

  cancellShift = () =>{
    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${98}`, {status : 0})
    .then(res => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      if (res.status == 200) {
        this.getDayLocationShifts();
        this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }

    }).catch(error => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)

    })
  }

  componentDidMount() {
    GlobalFunctions.extractToken();
    // this.calculateWeekRange();
    this.setState({locationSelectList :  []});
    GlobalFunctions.getCompanyLocations().then(res=>{
      this.setState({companyLocations : res});
      this.state.locationSelectList.push({label : 'All Locations', value : 0})
      res.forEach(element => {
        this.state.locationSelectList.push({
          value : element.id,
          label : element.name
        })
      });
    })
    this.getLocationUserData();
    this.getManagerUserData();
    this.getClients();
  }
  componentDidUpdate(){
    if(this.state.data!=this.props.data){
      this.setState({data : this.props.data});
      // const sortBy = "staffName";
      // const sortDirection = SortDirection.ASC;
      // const data =this.props.data
      // const sortedList = this._sortList({ sortBy, sortDirection,data});
      // this.setState({sortedList: sortedList})
      console.log(this.props.data)
    }
  }
  checkStaffAvailability =  (event) =>{
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/checkStaffAvailability?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&date=${event.date}&staffId=${event.staffId}`
    axios.get(url).then(result =>{
      this.setState({isStaffAvailable:result.data});
    }).catch(error =>{
      swal("Error when checking for staff availability!", " ", "warning");
      console.log(error);
    })
  }

  networkFailureAlert = (event) => {
    swal(event, " ", "warning")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAlert = () => {
    swal("successfully saved!", " ", "success")
      .then(() => {
        
        this.hideAddModal();
        this.hideModal();
      })
  }
  confirmAppointmentAlert = () => {
    swal("successfully confirmed!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAppointmentAlert = () => {
    swal("successfully saved!", " ", "success")
      .then(() => {
      })
  }
  weekCopyAlert = () => {
    swal("Week successfully copied!", " ", "success")
      .then(() => {
        this.hideCopyShiftsModal();
        this.hideModal();
      })
  }
  approveAlert = () => {
    swal("successfully approved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  deleteSaveAlert = () => {
    swal("successfully deleted!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }


  deleteAlert = (event) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.deleteShift(event);
        }
      })
  }

  
  popoverHoverFocus = (event) => {
    return (
      <Popover id="popover-trigger-hover-focus" title="Popover bottom">
        <table class="table table-bordered">
          {
            event.map((element) => {
              return (
                <tr>
                  <td>{element.clientName}</td>
                 {element.isNotClient || element.isActiveSleepOver==''?'' : <td>{element.isActiveSleepOver}</td>} 
                  <td>{element.startTime} - {element.endTime}</td>
                </tr>
              )
            })
          }
        </table>
      </Popover>
    )
  }
  row = (data, dayPoS, dayName) =>{
    const today = moment(this.state.weekStart).add(dayPoS, 'days').format('MM-DD-YYYY')
    const shiftData = data.shifts.filter(function (x) {return x.day==dayName;});
    var clientList = [];
    shiftData.forEach(element => {
      if (!clientList.includes(element.clientName)) {
        clientList.push({
          clientName : element.clientName?element.clientName: element.clientLocation,
          startTime : element.startTime,
          endTime : element.endTime,
          isNotClient : element.clientName? true : false,
          isActiveSleepOver : element.isActiveSleepOver!=null? element.isActiveSleepOver?'Awake' : 'Sleepover' : ''
        });
      }
    });
    return <div >
      {
        data.length!=0?
        <div className="mt-1">
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={this.popoverHoverFocus(clientList)}
            onMouseEnter={() => {
              this.setState({ clientList: clientList });
            }
            }
          >
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left">
              <div className="row">
                <div className="col-md-12">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{shiftData.length}  shifts &nbsp;</span></small>
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}> {0} Hour Shift </span></small>
                </div>

                <div className="col-md-6 ">
                <button 
                  onClick={(event)=>{
                    this.setState({coppiedShistDetails : {appointments : data}})
                  }}
                className="btn btn-outline-warning btn-sm "><small>Copy</small></button>
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm "
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        this.setState({ 
                          isCordinator: false, 
                          clientShiftOptionsShow:true,
                          selectedStaffId: data.staffId,
                          selectedShiftDate: today,
                          staffAppointments: data,
                          selectedStaffName: data.staffName,
                          hideLocationShiftInputFields : true,
                          })
                        // this.showAddAppointmentModal(today);
                        this.checkStaffAvailability({date:today, staffId: data.staffId})
                      }}
                    >
                      <small>Open</small>
                    </button>
                    <button
                        onClick={() => {
                          this.pasteShift( today, data.staffId );
                        }}

                        className="btn btn-outline-warning btn-sm"
                      >
                        <small>Paste</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </OverlayTrigger>
        </div>
        :
        <div>No</div>
      }
    </div>
  }

  render() {
    return (
      <div style={{ height: 400 }}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={20}
              rowHeight={60}
              rowCount={this.state.data.length}
              rowGetter={({ index }) => this.state.data[index]}
            >
              <Column label="Name" dataKey="staffName" width={200} />
              <Column label="Name" cellRenderer={({ rowData }) =>this.row(rowData, 0, 'Monday')} width={200} />
              <Column label="Mon" cellRenderer={({ rowData }) =>this.row(rowData, 1, 'Tuesday')} width={200} />
              <Column label="Tues" cellRenderer={({ rowData }) =>this.row(rowData, 2, 'Wednesday')} width={200} />
              <Column label="Wed" cellRenderer={({ rowData }) =>this.row(rowData, 3, 'Thursday')} width={200} />
              <Column label="Thur" cellRenderer={({ rowData }) =>this.row(rowData, 4, 'Friday')} width={200} />
              <Column label="Frid" cellRenderer={({ rowData }) =>this.row(rowData, 5, 'Saturday')} width={200} />
              <Column label="Sat" cellRenderer={({ rowData }) =>this.row(rowData, 6, 'Sunday')} width={200} />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }

  // _sortList = ({ sortBy, sortDirection, data}) => {
  //   let newList = _.sortBy(data, [sortBy]);
  //   if (sortDirection === SortDirection.DESC) {
  //     newList.reverse();
  //   }
  //   return newList;
  // };

  // _sort = ({ sortBy, sortDirection }) => {
  //   const sortedList = this._sortList({ sortBy, sortDirection });
  //   this.setState({ sortBy, sortDirection, sortedList });
  // };
}

