import React from "react";
import axios from "axios";
import moment from "moment";
import swal from '@sweetalert/with-react';
import date from 'date-and-time'

//const API_MAIN_URL=`https://rosterapi.tarisa.com.au/engage/`;
//const API_MAIN_URL=`http://localhost:4001`

class GlobalFunctions extends React.Component {
    
    static   requestUserFromAPI(){
         return axios.get(this.extractToken().API_URL+`/users/auth?TOCKEN=`+this.extractToken().TOCKEN).then(res=>{
            localStorage.setItem("staff_id", res.data.STAFF_ID);
            localStorage.setItem("access_level", res.data.ACCESS_LEVEL);
            return res.data;
        }).catch(error=>{
            this.networkFailureAlert(error);
            return error;//
        })
      }
    static extractToken() {
          var params = {};
          var parser = document.createElement('a');
          parser.href = window.location.href;
          var query = parser.search.substring(1);
          var vars = query.split('&');
          for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
          }
        
          const data = {
            TOCKEN : params.TOCKEN,
            ACCESS_LEVEL: localStorage.getItem('access_level'),
            STAFF_ID: localStorage.getItem('staff_id'),
            API_URL : params.API_URL,
            COMPANY_URL : params.COMPANY_URL,
            isXeroConnected : params.isXeroConnected
          }
          
          return data;
      }

      static networkFailureAlert(event) {
        let errorObject = JSON.parse(JSON.stringify(event));
        swal(errorObject, " ", "warning")
      }

      static cancelAppointmentAlert(event){
        swal({
          title: "You are about to Cancell an Client Shift?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then((willDelete) => {
           this.handleCancelClientAppointmentShift(event);
          })
      }
      static handleCancelClientAppointmentShift (event){
        var pos = 0;
        event.forEach(element=>{
          pos++;
          var url1 = `${this.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${this.extractToken().TOCKEN}`;
          axios.get(url1).then(client => {
            if(pos==event.length){
              this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
              swal("successfully cancelled!", " ", "success")
            }
          }).catch(error => {
            console.log(error);
            this.networkFailureAlert(error);
          })
        })
        
      }
      static getCompanyLocations(){
        var url = `${this.extractToken().API_URL}/shifts/companyLocations?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        return axios.get(url).then(result=>{
          return result.data;
        }).catch(error => {
          return []
        })
      }
      static calculateDurationOfShift (params) {
        var startTime= date.parse(params.startTime, 'HH:mm:ss A',true);
        var endTime =date.parse(params.endTime, 'HH:mm:ss A',true);
        var  duration = date.subtract(endTime, startTime).toHours();
        duration= Number((duration).toFixed(2));
        if (duration<0) {
            duration=duration*-1; 
        }
        return duration;
    }
    // ...etc
  }

  export default GlobalFunctions;