import React from 'react';
import moment from 'moment';

const secondWeekTimeSheetModalColumns = (state, thiis) => [{
    Header: () => (
      <button
        onClick={() => {
          var arr = [];
          arr = state.selectedSecondWeekTimesheetId;
          if (arr.length == 0) {
            state.secondWeekTimesheets.forEach(element => {
              arr.push(element)
            });
            thiis.setState({ selectedSecondWeekTimesheetId: arr });
          } else {
            thiis.setState({ selectedSecondWeekTimesheetId: [] });
          }
        }}
        className="btn text-white  btn-warning btn-block"
        type="button">
        &#10003;
      </button>
    ),
    maxWidth: 46,
    Cell: row => {

      return (
        <div
        >
          <input
            onClick={() => {
              var arr = [];
              arr = state.selectedSecondWeekTimesheetId;
              var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
              if (index >= 0) {
                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
              } else {
                arr.push(row.original)
              }
              thiis.setState({ selectedSecondWeekTimesheetId: arr });
            }
            }
            type="checkbox" className="ml-2" checked={state.selectedSecondWeekTimesheetId.some(item => item.shiftId === row.original.shiftId) ? true : false}>
          </input>
        </div >
      )
    }

  }, {

    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        <small>id</small>
      </button>
    ),
    maxWidth: 75
    ,
    Cell : row =>{
      return (
      <small>
        {
          row.original.isConcelled?
          <del><small>{row.original.shiftId}</small></del>
          :
          <div><small>{row.original.shiftId}</small></div>
        }
      </small>
      )
    }
  }, {
    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        Shift Details
      </button>
    ),
    Cell: row => {
      if(row.original.isDeleted){
        console.log(row.original)
      }
      
      var date = row.original.date;
      date = moment(date).format('llll');
      date = date.split(" ");
      return (
        <small
          onClick={() => {
            var arr = [];
            arr = state.selectedSecondWeekTimesheetId;
            var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
            if (index >= 0) {
              arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
            } else {
              arr.push(row.original)
            }
            thiis.setState({ selectedSecondWeekTimesheetId: arr });
          }}
          className={state.selectedSecondWeekTimesheetId.some(item => item.shiftId === row.original.shiftId) ? "bg-light" : ''}
        >
          <p style={row.original.isDeleted==true?{"color":"red","width":"100%", "padding":"0"}:{"width":"100%", "padding":"0"}} class="card-text container-fluid text-red">
            {`${row.original.clientName?`Attending ${row.original.clientName} @${row.original.location}`:`A shift @${row.original.location}`} 
            from ${row.original.startTime} to ${row.original.endTime}`}
          </p>
        </small>
      )
    }

  },{
    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        <small>Date</small>
      </button>
    ),
    maxWidth: 90,
    Cell: row => {
      var date = row.original.date;
      date = moment(date).format('llll');
      date = date.split(" ");
      return (
        row.original.isConcelled?
        <small>
          <del>
            <div>
              {
                date[0] + " " + date[1] + " " + date[2] 
              }
            </div>
          </del>
        </small>
        :
        <small>
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] 
            }
          </div>
        </small>
      )
    }

  }, {

    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        <small>Duration</small>
      </button>
    ),
    // accessor: 'duration',
    maxWidth: 80,
    Cell : row =>{
      return (
      <small>
        {
        row.original.isConcelled?
          <del>{row.original.duration}</del>
          :
          <div>{row.original.duration}</div>
        }
      </small>
        
      )
    }
  },
  {

    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        <small>Shift Type</small>
      </button>
    ),
    // accessor: 'duration',
    maxWidth: 80,
    Cell : row =>{
      return (
      <small>
        {
          row.original.isActiveSleepOver == null && <div> --- </div>
        }
        {
          row.original.isActiveSleepOver == true && <div>Sleep Over</div>
        }
        {
          row.original.isActiveSleepOver == false && <div>Awake</div>
        }
      </small>
        
      )
    }
  }
  , {
    Header: () => (
      <button className="btn text-white  btn-warning btn-block" type="button">
        Action
      </button>
    ),
    maxWidth: 230,
    Cell: row => {

      return (
        <div>
          {
            
            state.selectedSecondWeekTimesheetId.some(item => item.shiftId === row.original.shiftId)?
            <button className="btn btn-sm btn-success btn-block mr-2" type="button"
                onClick={() => {
                    var arr = [];
                    arr = state.selectedSecondWeekTimesheetId;
                    var index = arr.findIndex(x => x.shiftId == row.original.shiftId);
                    arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                    thiis.setState({ selectedSecondWeekTimesheetId: arr });
                }
                }>
                <small>Selected</small>
            </button>
            :
            <button className="btn btn-sm btn-outline-success btn-block mr-2" type="button"
                onClick={() => {
                    var arr = [];
                    arr = state.selectedSecondWeekTimesheetId;
                    arr.push(row.original);
                    thiis.setState({ selectedSecondWeekTimesheetId: arr });
                }
                }>
                <small>Select</small>
            </button>
          }
          
        </div>
      )
    }
  }];

  export default secondWeekTimeSheetModalColumns;