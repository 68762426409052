import React from 'react'
// import '.../styles/main.css';
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// import './styles/navbar.css';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import 'rsuite-table/dist/css/rsuite-table.css';
import "react-datepicker/dist/react-datepicker.css";


//SWEET-ALERT PROGRASS BAR
const SweetAlertProgressBar = props => <div>
    <p>{props.hearder}</p>
    <Progress percent={props.percent} status={props.percent==100?"success" : props.percent==0?'error' : ''} />
  </div>

export default SweetAlertProgressBar;